<template>
  <el-dialog
    :title="title"
    :visible="true"
    :width="width"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <div>{{ content }}</div>
    <div>
      <slot name="body" />
    </div>
    <div slot="footer" class="dialog-footer">
      <div class>
        <el-button v-if="!hideCancel" v-loading="cancelBtnLoading" plain @click="$emit('close')">
          {{ cancelBtnString }}
        </el-button>
        <el-button v-loading="confirmBtnLoading" :type="btnType" @click="$emit('confirm')">
          {{ btnString || '確認' }}
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'WarningDialog',

  props: {
    title: {
      type: String,
      default: '提示',
    },
    content: {
      type: String,
      default: '',
    },
    show: Boolean,
    width: {
      type: String,
      default: '440px',
    },
    btnString: String,
    cancelBtnString: {
      type: String,
      default: '取消',
    },
    hideCancel: {
      type: Boolean,
      default: false,
    },

    btnType: {
      type: String,
      default: 'primary',
    },

    confirmBtnLoading: {
      type: Boolean,
      default: false,
    },
    cancelBtnLoading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped lang="scss"></style>
