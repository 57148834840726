export const useFetch = () => {
  const simpleFetch = async (apiMethod, payload, callback, throwErr = false) => {
    const [res, err] = await apiMethod(payload)
    if (err) {
      if (throwErr) throw err
      console.log('err', err)
      window.$message.error(err.msg || err)
      return 'error'
    }
    if (callback) await callback(res)
    return res
  }

  const fetchAll = async (fetchAPI, payload, callback) => {
    let start = 0
    const data = []
    const _limit = 100

    while (true) {
      const [res, err] = await fetchAPI({
        ...payload,
        start,
        limit: _limit,
      })
      if (err) {
        console.log(err)
        window.$message.error(err)
        return
      }
      start += _limit
      data.push(...res)
      if (res.length < _limit) {
        break
      }
    }

    if (callback) await callback(data)
  }

  const fetchAllOld = async (fetchAPI, payload, callback) => {
    let start = 0
    const data = []
    const _limit = 100

    try {
      while (true) {
        const result = await fetchAPI({
          ...payload,
          start,
          limit: _limit,
        })
        start += _limit
        data.push(...result)
        if (result.length < _limit) {
          break
        }
      }

      if (callback) await callback(data)
    } catch (error) {
      window.$message.error(error)
    }
  }

  return { simpleFetch, fetchAll, fetchAllOld }
}
