import { admin, admin2 } from '@/api/instance'

const baseUrl = (shopId) => {
  return `/${shopId}/classTicket`
}

const record = (shopId) => {
  return `/${shopId}/classTicketRecord`
}
const useRecord = (shopId) => {
  return `/${shopId}/classTicketRecordUse`
}

//= > 建立堂票
export const CreateClassTicket = async ({
  shopId,
  name,
  description,
  availableTimes,
  isExp,
  expDay,
  specifyExpiredDate,
  order,
  notice,
  isValuable,
  isClientUsageAllowed,
  image,
  appointmentServices,
}) => {
  return await admin2({
    method: 'post',
    url: `${baseUrl(shopId)}`,
    data: {
      name,
      description,
      availableTimes,
      isExp,
      expDay,
      specifyExpiredDate,
      order,
      notice,
      isValuable,
      isClientUsageAllowed,
      image,
      appointmentServices,
    },
  })
}

//= > 取得堂票
export const GetClassTicket = async ({ shopId, start, limit, name }) => {
  return await admin2({
    method: 'get',
    url: `${baseUrl(shopId)}`,
    params: {
      start,
      limit,
      name,
    },
  })
}

//= > 取得堂票總數
export const GetClassTicketCount = async ({ shopId, name }) => {
  return await admin2({
    method: 'get',
    url: `${baseUrl(shopId)}/count`,
    params: {
      name,
    },
  })
}

//= > 查找堂票
export const FindClassTicket = async ({ shopId, id }) => {
  return await admin2({
    method: 'get',
    url: `${baseUrl(shopId)}/${id}`,
  })
}

//= > 更新堂票
export const UpdateClassTicket = async ({ shopId, id, name, description, image, notice, appointmentServices }) => {
  return await admin2({
    method: 'put',
    url: `${baseUrl(shopId)}/${id}`,
    data: {
      name,
      description,
      image,
      notice,
      appointmentServices,
    },
  })
}

//= > 刪除堂票
export const DeleteClassTicket = async ({ shopId, id }) => {
  return await admin2({
    method: 'delete',
    url: `${baseUrl(shopId)}/${id}`,
  })
}

// ! 紀錄

//= > 建立堂票紀錄
export const CreateClassTicketRecord = async ({
  shopId,
  memberId,
  classTicketId,
  quantity,
  price,
  note,
}) => {
  const res = await admin({
    method: 'post',
    url: `${record(shopId)}`,
    data: {
      memberId,
      classTicketId,
      quantity,
      price,
      note,
    },
  })

  return res.data
}

//= > 取得堂票紀錄
export const GetClassTicketRecord = async ({
  shopId,
  start,
  limit,
  code,
  MemberId,
  ClassTicketId,
}) => {
  const res = await admin({
    method: 'get',
    url: `${record(shopId)}`,
    params: {
      start,
      limit,
      code,
      MemberId,
      ClassTicketId,
    },
  })

  return res.data
}

export const GetClassTicketRecord2 = async ({
  shopId,
  start,
  limit,
  code,
  MemberId,
  ClassTicketId,
}) => {
  return await admin2({
    method: 'get',
    url: `${record(shopId)}`,
    params: {
      start,
      limit,
      code,
      MemberId,
      ClassTicketId,
    },
  })
}

//= > 取得堂票紀錄總數
export const GetClassTicketRecordCount = async ({ shopId, code, MemberId, ClassTicketId }) => {
  const res = await admin({
    method: 'get',
    url: `${record(shopId)}/count`,
    params: {
      code,
      MemberId,
      ClassTicketId,
    },
  })

  return res.data.count
}

//= > 查找堂票紀錄
export const FindClassTicketRecord = async ({ shopId, id }) => {
  const res = await admin({
    method: 'get',
    url: `${record(shopId)}/${id}`,
  })

  return res.data
}

//= > 使用堂票
export const UseClassTicket = async ({ shopId, id, times }) => {
  const res = await admin({
    method: 'post',
    url: `${record(shopId)}/${id}/use`,
    data: {
      times,
    },
  })

  return res.data
}

//= > 使用堂票
export const CancelUseClassTicket = async ({ shopId, id, times }) => {
  const res = await admin({
    method: 'post',
    url: `${record(shopId)}/${id}/useCancel`,
    data: {
      times,
    },
  })

  return res.data
}

//= > 取消堂票紀錄
export const CancelClassTicketRecord = async ({ shopId, id }) => {
  const res = await admin({
    method: 'put',
    url: `${record(shopId)}/${id}/cancel`,
  })

  return res.data
}

// 堂票使用紀錄
export const GetClassTicketUseRecord = async ({
  shopId,
  start,
  limit,
  code,
  MemberId,
  ClassTicketId,
  SerialActivityId,
  type,
}) => {
  const res = await admin({
    method: 'get',
    url: `${useRecord(shopId)}`,
    params: {
      start,
      limit,
      code,
      MemberId,
      ClassTicketId,
      SerialActivityId,
      type,
    },
  })

  return res.data
}

//= > 取得堂票紀錄總數
export const GetClassTicketUseRecordCount = async ({
  shopId, code, MemberId, ClassTicketId, SerialActivityId,
  type,
}) => {
  const res = await admin({
    method: 'get',
    url: `${useRecord(shopId)}/count`,
    params: {
      code,
      MemberId,
      ClassTicketId,
      SerialActivityId,
      type,
    },
  })

  return res.data.count
}

//= > 查詢堂票序號
export const GetClassTicketRecordSerial = async ({
  shopId,
  ClassTicketRecordId,
  createdAtStart,
  createdAtEnd,
  start,
  limit,
  MemberId,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/classTicketRecordSerial`,
    params: {
      ClassTicketRecordId,
      createdAtStart,
      createdAtEnd,
      start,
      limit,
      MemberId,
    },
  })
}

//= > 查詢堂票序號數量
export const GetClassTicketRecordSerialCount = async ({
  shopId,
  ClassTicketRecordId,
  createdAtStart,
  createdAtEnd,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/classTicketRecordSerial/count`,
    params: {
      ClassTicketRecordId,
      createdAtStart,
      createdAtEnd,
    },
  })
}

//= > 查詢堂票序號
export const FindClassTicketRecordSerial = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/classTicketRecordSerial/${id}`,
  })
}
