import dayjs from 'dayjs'

export const isInvalidDate = (date) => {
  if (!date) return true
  return date.toString() === 'Invalid Date'
}

//= > 取得指定日期的一天開始與結束
export const getDay = (day) => {
  const now = new Date(day)
  const start = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0)
  const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 24, 0, 0)

  return { start, end }
}

//= > 取得指定月份的開始與結束
export const getMonth = (year, month) => {
  const start = new Date(year, month - 1, 1, 0, 0, 0)
  const end = new Date(year, month, 1, 0, 0, 0)

  return { start, end }
}

//= > 取得當天一天的開始與結束
export const thisDay = () => {
  const now = new Date()
  const start = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0)
  const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 24, 0, 0)

  return { start, end }
}

//= > 取得當月的開始與結束
export const thisMonth = () => {
  const now = new Date()
  const start = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0)
  const end = new Date(now.getFullYear(), now.getMonth() + 1, 1, 0, 0, 0)

  return { start, end }
}

export const formatDate = (date, format = 'YYYY/MM/DD HH:mm') => {
  return dayjs(date).format(format)
}

export const weekDayTranslate = (day) => {
  const weekOfDay = {
    0: '星期日',
    1: '星期一',
    2: '星期二',
    3: '星期三',
    4: '星期四',
    5: '星期五',
    6: '星期六',
  }
  return weekOfDay[day]
}
